import React from 'react'

import { makeStyles } from '@material-ui/core/styles'
import { Button, Box, Grid, Container, Typography, useTheme, } from '@material-ui/core'
import Text from '../Text'
import { useMobile } from '../../utils/hooks'
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';



const useStyles = makeStyles(theme => ({
    list: {
        listStyle: 'none',
        [theme.breakpoints.up('xs')]: {
            padding: 0
        }
    },
    listItem: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'flex-start'
    },
    dotWrapper: {
        display: 'inline-block',
        color: theme.palette.primary.main,
        marginRight: 7,
    },
    dot: {
        verticalAlign: 'middle',
        marginTop: 2
    }
}))

const items = [
    <><b>Le 2026</b> la <b>moitié</b> des participants aux <b>grandes fortunes françaises</b> seront une <b>génération millenials</b></>,
    <><b>Le 2036</b> les <b>jeunes</b> générations constitueront déjà <b>plus de 60%</b> des millionnaires</>,
    <>approfondissement la <b>numérisation de la population</b></>,
    <><b>traditionnelles</b> peinent à rattraper un <b>retard technique</b> et n’est pas compensé par la qualité du conseil et des opportunités proposées</>,
    <><b>taux</b> toujours plus bas, vu comme <b>négatif</b></>,
    <>commoditisation des services de gestion de portefeuille</>,
    <><b>disparition des actifs sans risques</b></>,
    <>la conscience des investisseurs à privilégier des <b>opportunités d’investissement en ligne</b> avec leur temps et dont l’impact est positif</>
]

const IndexInfo = props => {
    const classes = useStyles()
    const mobile = useMobile()

    const columns = mobile ? [items] : [items.slice(0, items.length / 2), items.slice(items.length / 2)]

    return (
        <Box id="info" py={mobile ? 4 : 8}>
            <Container>
                <Text variant="h2" className={classes.header} mb={4} color='primary.mainGradient' textAlign="center" data-sal="fade" data-sal-duration="500" data-sal-delay="200">
                    À propos du marché financier aujourd'hui
                </Text>

                <Box mt={4} data-sal="fade" data-sal-duration="1000" data-sal-delay="400">
                    <Grid container className={classes.list} spacing={2}>
                        {columns.map(col => <Grid item container xs={12} sm={6} spacing={2}>
                            {col.map(item =><Grid item className={classes.listItem}>
                                <Box className={classes.dotWrapper}>
                                    <FiberManualRecordIcon fontSize="inherit" className={classes.dot} />
                                </Box>

                                <Text variant="body1" style={{ display: 'inline' }}>
                                    {item}
                                </Text>
                            </Grid>)}
                        </Grid>)}
                    </Grid>
                </Box>
            </Container>
        </Box>
    )
}

export default IndexInfo
