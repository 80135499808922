import React from 'react'
import Layout from '../components/Layout'
import Seo from '../components/Seo'
import IndexInfo from '../components/index/IndexInfo'
import IndexContact from '../components/index/IndexContact'
import IndexHeroSection from '../components/index/IndexHeroSection'


const IndexPage = props => {
  return (
    <Layout>
      <Seo title="Incubit" />

      <IndexHeroSection />

      <IndexInfo />

      <IndexContact />
    </Layout>
  )
}

export default IndexPage
