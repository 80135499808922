import React from 'react'

import { makeStyles } from '@material-ui/core/styles'
import { Box, Button, Card, Container, Grid, MenuItem, TextField, Checkbox, FormControlLabel, } from '@material-ui/core'
import Text from '../Text'
import { useMobile } from '../../utils/hooks';

const useStyles = makeStyles(theme => ({
    card: {
        border: `2px solid ${theme.palette.primary.main}`,
    },
}))


const IndexContact = props => {
    const classes = useStyles()
    const mobile = useMobile()

    return (
        <Box id="contact">
            <Container>
                <Container component={Card} className={classes.card} elevation={12} maxWidth="md" data-sal="fade"
                    data-sal-duration="1000" data-sal-delay="600">
                    <form name='contact' method="POST" action="/confirm" data-netlify="true" netlify-honeypot="bot-field">
                        <input type="hidden" name="form-name" value="contact" />
                        <Box px={mobile ? 0 : 4} py={mobile ? 3 : 6}>
                            <Text variant="h2" color="primary.mainGradient" textAlign="center">
                                Do you know Incubit?
                        </Text>

                            <Text variant="h4" color="text.secondary" mb={mobile ? 3 : 6} textAlign="center">
                                invest in innovative projects from all over Europe
                        </Text>

                            <Grid container spacing={3}>
                                <Grid item xs={12} sm={6}>
                                    <TextField fullWidth variant="outlined" label="Your name" placeholder=" " required
                                        name={"name"} />
                                </Grid>

                                <Grid item xs={12} sm={6}>
                                    <TextField fullWidth variant="outlined" label="Email" placeholder=" " name={"email"} required />
                                </Grid>

                                <Grid item xs={12}>
                                    <TextField fullWidth select variant="outlined" label="Your experience in investing" required
                                        name={"experiences"}>
                                        <MenuItem value={'Venture Capital'}>Venture Capital</MenuItem>
                                        <MenuItem value={'Professional investor'}>Professional investor</MenuItem>
                                        <MenuItem value={'Beginner investor'}>Beginner investor</MenuItem>
                                    </TextField>
                                </Grid>

                                <Grid item xs={12}>
                                    <TextField fullWidth select variant="outlined"
                                        label="What would you like to invest in?" placeholder=" " required
                                        name={'projects'}>
                                        <MenuItem value={'Pre-seed'}>Pre-seed</MenuItem>
                                        <MenuItem value={'Startup'}>Startup</MenuItem>
                                        <MenuItem value={'Scaleup'}>Scaleup</MenuItem>
                                    </TextField>
                                </Grid>

                                <Grid item xs={12}>
                                    <Box display="flex" justifyContent="center">
                                        <FormControlLabel                                            
                                            control={<Checkbox color="primary" value="true" name="newsletterConfirm" />}
                                            label="I agree to receive a newsletter from Incubit."
                                        />
                                    </Box>
                                </Grid>
                            </Grid>

                            <Box mt={mobile ? 3 : 4} textAlign="center">
                                <Button variant="contained" color="primary" type={'subbmit'}>
                                    Sign up
                            </Button>
                            </Box>
                        </Box>
                    </form>
                </Container><br /><br /><br />
                <Text variant="h4" color="text.secondary" mb={mobile ? 3 : 6} textAlign="center" data-sal="fade"
                    data-sal-duration="1000" data-sal-delay="200">
                    Selon un modèle de <b>financement participative</b>, vous pouvez participer directement au développement de <b>projets innovants</b> dans la plupart des pays <b>européens</b> et profitez sur <b>le marché secondaire d’actions</b> de projets déjà financés.<br /><br />
                    Grâce à <b>incubit restart</b>, vous pouvez aussi <b>soutenir votre café et restaurant préféré</b> et participer directement dans la <b>“financement en échange de royalties”</b> (Revenue Based Finance).
                        </Text>
            </Container>
        </Box>

    )
}

export default IndexContact
