import React from 'react'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import { Button, Box, Container, Fade } from '@material-ui/core'
import IndexBgImage from './IndexBgImage'
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Text from '../Text';
import { ScrollToSectionContext, HeaderHeightContext } from '../Layout';


const IndexHeroSection = props => {
    const [showButton, setShowButton] = React.useState(true)
    const theme = useTheme()
    const mobile = useMediaQuery(theme.breakpoints.only('xs'));
    const scrollToSection = React.useContext(ScrollToSectionContext)
    const headerHeight = React.useContext(HeaderHeightContext)

    const handleScroll = () => {
        if (window.scrollY === 0) {
            setShowButton(true)
        } else if (window.scrollY > 0) {
            setShowButton(false)
        }
    }

    React.useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, [])

    return (
        <Box
            minHeight="100vh"
            display="flex"
            flexDirection="column"
            justifyContent="center"
            overflow="hidden"
            position="relative"
            bgcolor={theme.palette.secondary.dark}
            style={mobile ? { paddingTop: headerHeight } : undefined}
        >
            <IndexBgImage bgcolor="secondary.dark" />

            <Container>
                <Box>
                    <Text className="tlt" variant="h1" color="primary" mb={mobile ? 2 : 4}>
                        Equity crowdfunding stock platform
                    </Text>

                    <Box maxWidth={600}>
                        <Text variant="subtitle1" color="white" mb={mobile ? 2 : 4} data-sal="fade" data-sal-duration="2000" data-sal-delay="600">
                            J't'écris toujours, <b>quand la menace!</b><br />
                            <b>Du fond de la crise</b>,  tout fait me glace...<br />
                            J'te dis encore, <b>que les hivers sont morts</b>,<br />
                            rallonge tes tresses, allez planque tes fesses!
                        </Text>

                        <Text variant="subtitle1" color="white" data-sal="fade" data-sal-duration="2000" data-sal-delay="800">
                            Est-ce que je t'ai dis <b>l'histoire de cet homme</b>,<br />
                            <b>que voulait tout et reste dégueulasse!</b><br />
                            Regarde là-bas au bout de mon doigt!<br />
                            <b>Si rien ne bouge – l’incubit devient rouge...</b>
                        </Text>
                    </Box>
                </Box>
            </Container>

            {!mobile && <Box
                zIndex={8}
                display="flex"
                width="100%"
                position={mobile ? "relative" : "absolute"}
                justifyContent="center"
                mt={mobile ? 2 : undefined}
                bottom={mobile ? undefined : 0}
                data-sal="fade"
                data-sal-delay="1000"
            >
                <Fade in={showButton} key={+ new Date()}>
                    <Button variant="contained" color="primary" onClick={scrollToSection('info')}>
                        En savoir plus
                    </Button>
                </Fade>
            </Box>}

        </Box>
    )
}

export default IndexHeroSection
